<template>
  <div>
    <div class="d-flex" v-if="!noData">
      <strategy-parts-ref-bar
        @click-expand="expand = $event"
        @change-status="refAreaStatus = $event"
        class="mr-8"
      ></strategy-parts-ref-bar>
      <div v-if="isInit" v-show="!expand" class="strategy-edit-wrap mx-auto" ref="strategy-edit-wrap" v-resize="onResize">
        <!-- 更新情報 -->
        <div class="d-flex justify-end mt-2 mr-5">
          <sheet-info
            v-model="sectionInfo"
            class="bottommost-section"
          ></sheet-info>
        </div>
        <!-- 戦略パーツ -->
        <div class="strategy-edit-main-container mb-10 px-0">
          <v-container>
            <!-- 戻るボタン -->
            <v-row>
              <v-col align="start">
                <v-btn text color="btPrimary" dark @click="$router.back()">
                  <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
                  <span class="black--text">前のページに戻る</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mb-8">
              <!-- パーツタイトル -->
              <v-col
                cols="12"
                class="text-h5 font-weight-medium pb-1"
                align="center"
              >
                {{ section.label }}
              </v-col>
              <!-- パーツ説明文 -->
              <v-col cols="12" class="pa-0">
                <hr class="page-title-hr" />
              </v-col>
              <v-col cols="12" class="pt-1 mb-3" align="left">
                <span v-html="section.description"></span>
              </v-col>
              <!-- パーツ表示部 -->
              <v-col>
                <v-form ref="form" style="width: 100%" class="d-flex justify-lg-center">
                  <section-resolver
                    :elementId="section.dataRef"
                    :name="strategyPartsMetaDict[section.dataRef].comp"
                    :inputStatus="inputStatus"
                    :ref="section.name"
                    v-model="partsData"
                    :hasWritePermission="hasWritePermission"
                    :dataInit="dataInit"
                    @update="needUpdate = true"
                    @mounted="mounted = true"
                  ></section-resolver>
                </v-form>
              </v-col>
            </v-row>
            <v-row v-show="hasWritePermission" class="mb-6" justify="center">
              <!-- パーツ登録ボタン -->
              <v-col cols="5" align="right">
                <v-btn-accent
                  label="保存する"
                  icon="mdi-content-save-outline"
                  @click="onClickUpdate"
                >
                </v-btn-accent>
              </v-col>
              <v-col cols="1"></v-col>
              <!-- パーツ削除ボタン -->
              <v-col cols="5" align="left">
                <v-btn-cancel
                  label="パーツを削除する"
                  @click="dialog.delete = true"
                ></v-btn-cancel>
              </v-col>
            </v-row>
            <!-- 戻るボタン -->
            <v-row>
              <v-col align="center">
                <v-btn text color="btPrimary" dark @click="$router.back()">
                  <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
                  <span class="black--text">前のページに戻る</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <!-- 入力サンプル -->
          <reference-document-dialog></reference-document-dialog>
          <reference-image-dialog></reference-image-dialog>
          <!-- 更新確認ダイアログ -->
          <yes-no-dialog
            :message="'入力した内容で更新しますか？'"
            v-model="dialog.update"
            yesText="更新する"
            noText="キャンセル"
            center
            @clickYes="onClickUpdateConfirm"
            @clickNo="dialog.update = false"
          />
    
          <!-- 削除確認ダイアログ -->
          <yes-no-dialog
            :message="section.label + 'を削除します。よろしいですか？'"
            v-model="dialog.delete"
            yesText="削除する"
            noText="キャンセル"
            center
            @clickYes="onClickDelete"
            @clickNo="dialog.delete = false"
          />
          <!-- 遷移確認ダイアログ -->
          <yes-no-dialog
            :message="'編集中のものは保存されませんが、よろしいですか？'"
            v-model="dialog.leaveConfirm"
            yesText="保存せずに離れる"
            noText="キャンセル"
            center
            @clickYes="cb()"
            @clickNo="
              dialog.leaveConfirm = false;
              cb(false);
            "
          />
          <!-- 入力エラー時ダイアログ -->
          <yes-no-dialog
            message="入力内容に不備があります。"
            v-model="dialog.invalid"
            yesText="戻る"
            noText=""
            center
            @clickYes="dialog.invalid = false"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <v-row no-gutters class="text-center pt-5">
        <v-col cols="12">戦略パーツが登録されていません。</v-col>
        <v-col cols="12">
          <v-btn text color="btPrimary" dark @click="$router.back()">
            <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
            <span class="black--text">前のページに戻る</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import strategyPartsMetaDict, {
  StrategyPartsMetaDictItem,
} from "../../../utils/strategyPartsMetaDict";
import strategyPartsInitData from "../../../utils/strategyPartsInitData";

import SectionResolver from "@/components/util/SectionResolver";
import YesNoDialog from "../atoms/YesNoDialog.vue";
import SectionStatus from "../molecules/SectionStatus.vue";
import ReferenceDocumentDialog from "../atoms/ReferenceDocumentDialog.vue";
import ReferenceImageDialog from "../atoms/ReferenceImageDialog.vue";
import SheetInfo from "../organisms/SheetInfo.vue";
import StrategyPartsRefBar from "@/components/atomic/organisms/StrategyPartsRefBar.vue";

import VBtnAccent from "@/components/atomic/atoms/VBtnAccent.vue";
import VBtnCancel from "@/components/atomic/atoms/VBtnCancel.vue";

export default {
  props: ["paramSectionId"],
  components: {
    SectionResolver,
    YesNoDialog,
    SectionStatus,
    ReferenceDocumentDialog,
    ReferenceImageDialog,
    SheetInfo,
    StrategyPartsRefBar,
    VBtnAccent,
    VBtnCancel,
  },
  data() {
    return {
      isInit: false,
      dataInit: false,
      needUpdate: false, //更新API呼出の要否フラグ
      section: null,
      value: null,
      inputStatus: null,
      sectionInfo: null,
      strategyPartsMetaDict,
      partsMeta: new StrategyPartsMetaDictItem(),
      expand: false,
      refAreaStatus: "close",
      cb: null,
      mounted: false,
      timerObj: null,
      resizeTimerObj: null,
      dialog: {
        delete: false,
        update: false,
        moveConfirm: false,
        leaveConfirm: false,
        invalid: false,
      },

      noData: false,
    };
  },
  computed: {
    ...mapGetters([
      "hasWritePermission",
      "project",
      "registeredSections",
      "editablePositioningMap",
      "registeredSection",
      "userInfo",
    ]),

    partsData() {
      if(!this.value) return null
      return this.partsMeta.inputDataKey ? this.value[this.partsMeta.inputDataKey] : this.value;
    }
  },
  methods: {
    ...mapActions([
      "getRegisteredSections",
      "deleteSelectedSection",
      "updateSelectedSection",
      "fetchSelectedSection",
    ]),
    async onClickDelete() {
      // 戦略パーツをプロジェクトから解除する
      this.unregisterSection();

      this.dialog.delete = false;
      this.needUpdate = false;

      this.moveToTopPage();
    },
    onClickUpdate() {
      // バリデーションチェック
      if(!this.$refs.form.validate()){
        this.dialog.invalid = true;
        return;
      }
      this.dialog.update = true;
    },
    async onClickUpdateConfirm() {
      

      // 更新処理
      this.updateSection();

      this.dialog.update = false;
      this.needUpdate = false;

      // トップページに遷移
      this.moveToTopPage();
    },
    async updateSection() {
      let body = {
        sectionId: this.section.sectionId,
        inputStatus: this.inputStatus,
        userId: this.userInfo.userId
      };

      // セクション情報を更新
      await this.updateSelectedSection(body);

      // 入力データを更新
      body = this.value;
      await this.$store.dispatch(this.partsMeta.updateAction, body);
    },
    async unregisterSection() {
      // 選択セクション情報を削除する
      this.deleteSelectedSection(this.section.sectionId);

      // 入力データを初期化する
      if (this.section.dataRef in strategyPartsInitData) {
        if(this.partsMeta.inputDataKey !== null) {
          this.value[this.partsMeta.inputDataKey] =
          strategyPartsInitData[this.section.dataRef];
        } else {
          this.value = strategyPartsInitData[this.section.dataRef];
        }
      }

      await this.$store.dispatch(this.partsMeta.updateAction, this.value);
    },
    moveToTopPage() {
      this.$router.push({
        name: "StrategyTopPage",
        params: { projectId: this.project.id },
      });
    },
    async getCurrentSection() {
      if (this.paramSectionId) {
        // paramSectionId がある場合は単体で取得する。
        await this.fetchSelectedSection(this.paramSectionId);
        this.section = this.registeredSection.section;
        this.sectionInfo = this.registeredSection.sectionInfo;
        this.inputStatus = this.registeredSection.inputStatus;
      } else {
        // リロード時など は paramSectionId がないので全体の中から選ぶ
        await this.getRegisteredSections(this.$route.params.projectId);
        var sections = this.registeredSections.sections.filter((item) => {
          if (item.section.dataRef == this.$route.params.partsName) {
            return true;
          }
        });

        if (sections.length > 0) {
          this.section = sections[0].section;
          this.sectionInfo = sections[0].sectionInfo;
          this.inputStatus = sections[0].inputStatus;
        } else {
          // セクションが取得できない場合はエラーを表示する
          this.noData = true;
        }
      }
    },
    async init() {
      // セクションの一覧を取得
      await this.getCurrentSection();
      if(this.noData) return;

      // メタ情報辞書から戦略パーツメタ情報を取得する
      this.partsMeta = strategyPartsMetaDict[this.section.dataRef];

      // データを取得する
      await this.$store.dispatch(this.partsMeta.fetchAction);
      this.value = this.$store.getters[this.partsMeta.getterKey];
      this.isInit = true;

      //データ初期化確認用タイマー
      this.timerObj = setInterval(() => {
        if (this.mounted) {
          this.dataInit = true;
          clearInterval(this.timerObj);
        }
      }, 1000);
    },

    onResize() {
      this.fireWrapAreaResize();
    },

    fireWrapAreaResize() {
      if(this.resizeTimerObj) {
        clearTimeout(this.resizeTimerObj);
      }

      this.resizeTimerObj = setTimeout(() => {
        this._fireWrapAreaResize();    
      }, 500);
    },
    _fireWrapAreaResize() {
      const target = this.$refs["strategy-edit-wrap"];

      if(target) {
        const width = target.getBoundingClientRect().width;
        // console.log(width);
        this.$store.commit("relativeScrollableArea/areaWidth", width);
      }
    },

    beforeUnload(e) {
      if (this.needUpdate) {
        e.returnValue = ""
      }
    }
  },
  async created() {
    await this.init();
    window.addEventListener("beforeunload", this.beforeUnload);
  },
  beforeDestroy() {
    if (this.timerObj) clearInterval(this.timerObj);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.beforeUnload)
  },
  beforeRouteLeave(to, from, next) {
    if (this.needUpdate) {
      // コールバックを設定して遷移確認ダイアログを表示
      this.cb = next;
      this.dialog.leaveConfirm = true;
    } else {
      next();
    }
  },
  watch: {
    refAreaStatus(val) {
      this.fireWrapAreaResize();
    },
    '$route.params.partsName': async function() {
      this.isInit = false;
      await this.init();
      this.isInit = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.strategy-edit-wrap {
  @include scrollAreaX();
  width: 100%;
  letter-spacing: 0.0125em;
}
.strategy-edit-main-container {
  margin: 0 auto;
  max-width: 1040px;
}
</style>
